import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from "@mui/material/FormControl";
import Grid2 from "@mui/material/Grid2";

import { DatePicker, MultiSelect, Search, Slider } from "../fields";
import { FilterFormProps, Filters } from "../../@types/apiTypes";
import { initialValues } from "../NewContentWrapper";
import { styles } from "../fields/styles";


const lightBlueColor = "rgba(51, 188, 220, 0.1)";


interface FilterComponentProps {
  /** The current filter state, containing all filtering criteria. */
  filterState: FilterFormProps;
  /** Function to update the filter state when the user submits a new set of filters. */
  updateValues: (values: FilterFormProps) => void;
  /** Optional boolean indicating whether data is currently loading. */
  isLoading?: boolean;
  /** The available filter options, such as universities, languages, etc. */
  filterOptions: Filters | undefined;
}

/**
 * `FilterComponent` provides filtering functionality for searching courses.
 * Users can filter courses by host university, language, format, level,
 * start and end dates, and ECTS value.
 *
 * @component
 * @example
 * ```tsx
 * <FilterComponent
 *   filterState={currentFilters}
 *   updateValues={setFilters}
 *   isLoading={isLoading}
 *   filterOptions={availableFilters}
 * />
 * ```
 *
 * @param {FilterComponentProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered `FilterComponent`.
 */
export const FilterComponent: React.FC<FilterComponentProps> = (props) => {
  /** Local state to manage the form inputs. */
  const [form, setForm] = React.useState<FilterFormProps>(props.filterState);

  // Sync form with updated props
  React.useEffect(() => {
    setForm(props.filterState);
  }, [props.filterState]);

  /**
   * Updates a specific filter field in the form.
   * @param {string} fieldName - The name of the field to update.
   * @param {any} fieldValue - The new value for the field.
   */
  const handleFieldUpdate = (fieldName: string, fieldValue: any) => {
    return setForm({ ...form, [fieldName]: fieldValue });
  };

  /**
   * Handles form submission, triggering an update if filters have changed.
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form === props.filterState) {
      return;
    } else {
      props.updateValues(form);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => handleSubmit(e)}
      sx={{
        backgroundColor: lightBlueColor,
        py: 2,
        px: { xs: "1rem", sm: 3 }
      }}
    >

      <Grid2 container spacing={2} >

        {/* First full row item, 12 column based layout. xs={12} means that starting
        from smallest size, the item takes up 12 columns */}
        <Grid2 size={{ xs:12 }}>
          <FormControl sx={{ width: "100%" }}>
            <Search
              id="search"
              name="q"
              value={form.q}
              onChange={(e) => handleFieldUpdate("q", (e.currentTarget ?? e.target).value)}
            />
          </FormControl>
        </Grid2>


        {/* Second row, 7 inputs */}
        <Grid2 size={{ xs:12 }} container spacing={2}>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <MultiSelect
              name="university"
              label="Hosts"
              value={form.university}
              options={props.filterOptions?.universities}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <MultiSelect
              name="language"
              label="Languages"
              value={form.language}
              options={props.filterOptions?.languages}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <MultiSelect
              name="modeOfDelivery"
              label="Formats"
              value={form.modeOfDelivery}
              options={props.filterOptions?.formats}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <MultiSelect
              name="level"
              label="Level"
              value={form.level}
              options={props.filterOptions?.level}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <DatePicker
              name="from"
              label="Select start date"
              value={form.from}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 'grow'}}>
            <DatePicker
              name="to"
              label="Select end date"
              value={form.to}
              cb={handleFieldUpdate}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6, lg: 'grow' }} >
            <Slider
              name="ectsMin"
              value={[form.ectsMin, form.ectsMax]}
              cb={handleFieldUpdate}
            />
          </Grid2>

        </Grid2>

        {/* Third row: buttons */}
        <Grid2 container size={{ xs:12 }}
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            alignSelf: "center",
            textAlign: "center",
            justifyContent: "end",
            alignItems: "center",
          }}>

          <Grid2 size={{ xs: 12, md: 1.5 }}
            component={Button}
            type="submit"
            variant="contained"
            disabled={props.isLoading}
            sx={{ ...styles.submitButtonStyles, order: { xs: 1, lg: 2 } }}
          >
            {props.isLoading ? <CircularProgress sx={{ display: 'inline' }} /> : "Filter"}
          </Grid2>

          <Grid2
            size={{ md: 'grow' }}
            component={Button}
            type="reset"
            onClick={() => setForm(initialValues)}
            sx={{ fontSize: '.8rem', color: "enlightTeal.main", order: { xs: 2, lg: 1 } }}
          >
            Reset filter
          </Grid2>

        </Grid2>
      </Grid2>

    </Box>
  );
};
