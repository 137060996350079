import { CourseFormatOptionsProps, LanguageOptions, LevelOptionsProps, UniversitiesOptions, UniversityLinks } from "./@types/filterTypes";



const universities: UniversitiesOptions[] = [
  { key: "COMENIUS_UNIVERSITY_BRATISLAVA", label: "Comenius University Bratislava"},
  { key: "GHENT_UNIVERSITY", label: "Ghent University" },
  { key: "UNIVERSITY_OF_GALWAY", label: "University of Galway" },
  { key: "UNIVERSITY_OF_BORDEAUX", label: "University of Bordeaux" },
  { key: "UNIVERSITY_OF_GRONINGEN", label: "University of Groningen" },
  { key: "UNIVERSITY_OF_GOTTINGEN", label: "University of Göttingen" },
  { key: "UNIVERSITY_OF_TARTU", label: "University of Tartu" },
  { key: "UNIVERSITY_OF_THE_BASQUE_COUNTRY", label: "University of the Basque Country" },
  { key: "UPPSALA_UNIVERSITY", label: "Uppsala University" },
  { key: "UNIVERSITY_OF_BERN", label: "University of Bern"}
];

/* const languages: LanguageOptions[] = [
  { key: "en", label: "English" },
  { key: "et", label: "Estonian" },
  { key: "nl", label: "Dutch" },
  { key: "fr", label: "French" },
  { key: "de", label: "German" },
  { key: "sk", label: "Slovak" },
  { key: "es", label: "Spanish" },
  { key: "se", label: "Swedish" }
]; */
const languages: LanguageOptions[] = [
  { key: "eng", label: "English" },
  { key: "est", label: "Estonian" },
  { key: "dut", label: "Dutch" },
  { key: "fre", label: "French" },
  { key: "ger", label: "German" },
  { key: "slo", label: "Slovak" },
  { key: "spa", label: "Spanish" },
  { key: "swe", label: "Swedish" }
];

const courseFormatOptions: CourseFormatOptionsProps[] = [
  { key: "online", label: "Virtual" },
  { key: "hybrid", label: "Blended" },
  { key: "on campus", label: "Physical" }
];

const levelOptions: LevelOptionsProps[] = [
  { key: "bachelor", label: "Bachelor" },
  { key: "master", label: "Master" },
  { key: "doctoral", label: "Doctorate" }
];

const universityLinks: UniversityLinks  = {
  abroad: {
    "Comenius University Bratislava": {
      incoming: {
        text: 'Students get automated emails with instructions how to proceed upon nomination.'
      },
      outgoing: {
        eng: 'https://uniba.sk/en/international-relations/eninternational-relationserasmus/students-study/'
      }
    },
    "Ghent University": {
      incoming: {
        eng: 'https://www.ugent.be/prospect/en/administration/application/application-exchange/overview.htm'
      },
      outgoing: {
        eng: 'https://www.ugent.be/student/en/class-exam-exchange-intern/exchange-ugent-students',
        native: 'https://www.ugent.be/student/nl/studeren/naar-buitenland'
      }
    },
    "University of Galway": {
      incoming: {
        eng: 'https://www.universityofgalway.ie/erasmus-programme/incomingstudents/'
      },
      outgoing: {
        eng: 'https://www.universityofgalway.ie/erasmus-programme/outgoingstudents/',
        native: 'https://www.universityofgalway.ie/erasmus-programme/outgoingstudents/'
      }
    },
    "University of Bordeaux": {
      incoming: {
        eng: 'https://www.u-bordeaux.fr/en/international/come-to-bordeaux/international-students/exchange-programmes'
      },
      outgoing: {
        eng: 'https://www.u-bordeaux.fr/en/international/opportunities-abroad/student-mobility/study-abroad'
      },
    },
    "University of Groningen": {
      incoming: {
        eng: 'https://www.rug.nl/education/exchange/',
      },
      outgoing: {
        eng: 'https://student.portal.rug.nl/infonet/studenten/opleidingen/meer-studiemogelijkheden/studeren-buitenland/',
        native: 'https://student.portal.rug.nl/infonet/studenten/opleidingen/meer-studiemogelijkheden/studeren-buitenland/'
      }
    },
    "University of Göttingen": {
      incoming: {
        native: 'https://www.uni-goettingen.de/de/48649.html'
      },
      outgoing: {
        native: 'https://www.uni-goettingen.de/de/48649.html'
      }
    },
    "University of Tartu": {
      incoming: {
        eng: 'https://ut.ee/en/content/exchange-studies'
      },
      outgoing: {
        eng: 'https://ut.ee/en/studyabroad',
        native: 'https://ut.ee/et/valismaa'
      }
    },
    "University of the Basque Country": {
      incoming: {
        eng: 'https://www.ehu.eus/en/web/nazioarteko-harremanak/en-international-visitors',
        native: 'https://www.ehu.eus/es/web/nazioarteko-harremanak/ikasle-bisitariak'},
      outgoing: {
        native: 'https://www.ehu.eus/es/web/nazioarteko-harremanak/ehuko-ikasleak'
      }
    },
    "Uppsala University": {
      incoming: {
        eng: 'https://www.uu.se/en/study/exchange-studies'
      },
      outgoing: {
        eng: 'https://www.uu.se/en/students/international-opportunities/courses-abroad/exchange-studies-via-university-wide-agreements',
        native: 'https://www.uu.se/student/internationella-mojligheter/kurser-utomlands/utbytesstudier-via-centrala-avtal'
      }
    },
    "University of Bern": {
      incoming: {
        eng: 'https://www.unibe.ch/studies/mobility/incoming/general_information/index_eng.html',
        native: 'https://www.unibe.ch/studium/mobilitaet/incoming/allgemeine_informationen/index_ger.html'
      },
      outgoing: {
        eng: 'https://www.unibe.ch/studies/mobility/outgoing/overview/index_eng.html',
        native: 'https://www.unibe.ch/studium/mobilitaet/outgoing/ueberblick/index_ger.html'
      }
    }
  },
  blended: {
    "Comenius University Bratislava": {},
    "Ghent University": {
      incoming: {
        eng: 'https://www.ugent.be/en/programmes/incoming-bip.htm'
      },
      outgoing: {
        eng: 'https://www.ugent.be/student/en/class-exam-exchange-intern/exchange-ugent-students/bip.htm',
        native: 'https://www.ugent.be/student/nl/studeren/naar-buitenland/stappenplan-bip'
      }
    },
    "University of Galway": {},
    "University of Bordeaux": {},
    "University of Groningen": {},
    "University of Göttingen": {},
    "University of Tartu": {
      incoming: {
        eng: 'https://ut.ee/en/exchange-students'
      },
      outgoing: {
        eng: 'https://ut.ee/en/content/new-enlight-courses-autumn-semester-23-24',
        native: 'https://ut.ee/et/sisu/enlighti-sugissemestri-kursused-2023-24'}
    },
    "University of the Basque Country": {},
    "Uppsala University": {
      outgoing: {
        eng: 'https://www.uu.se/en/students/international-opportunities/courses-abroad/freestanding-courses-and-summer-courses',
        native: 'https://www.uu.se/student/internationella-mojligheter/kurser-utomlands/fristaende-kurser-och-sommarkurser'
      }
    },
    "University of Bern": {}
  }
};




export { universities, languages, levelOptions, courseFormatOptions, universityLinks };
