import * as React from "react";
import { DateTime } from "luxon";

import { FormControl } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

import { FieldProps } from ".";
import { styles } from "./styles";

import { BackendAcceptedDateFormat } from "../../@types/filterTypes";


export const DATE_INPUT_FORMAT = "dd/MM/yyyy";
export const API_DATE_FORMAT = "yyyy-MM-dd";

interface DatePickerFieldProps {
  label: string;
}

type DPP = FieldProps<BackendAcceptedDateFormat> & DatePickerFieldProps;

export const DatePicker: React.FC<DPP> = (props) => {


  const handleChange = (value: DateTime | null) => {
    if ( value ) {
      const newValue = value.toFormat(API_DATE_FORMAT);
      if (props.cb) {
        props.cb(props.name, newValue);
      }
    } else {
      if (props.cb) {
        props.cb( props.name, "" );
      }
    }
  };

  // Convert the string value to DateTime for the DatePicker
  const dateTimeValue = props.value.length > 0
    ? DateTime.fromFormat(props.value, API_DATE_FORMAT)
    : null;


  return (
    <FormControl fullWidth>
      <MuiDatePicker
        showDaysOutsideCurrentMonth
        format={DATE_INPUT_FORMAT}
        onChange={ handleChange }
        slotProps={{
          textField: {
            fullWidth: true,
            label: props.label,
            InputLabelProps: {
              sx: styles.datePickerLabel,
              shrink: false
            },
            sx: {
              "& .MuiInputBase-root": {
                minWidth: "100%",
                height: "2rem",
                backgroundColor: "white"
              }
            },
            placeholder: DATE_INPUT_FORMAT
          }
        }}
        {...props}
        value={dateTimeValue}
      />
    </FormControl>
  );
};
