import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


interface InfoPieceProps {
  name: string;
  content: React.ReactNode;
  list?: boolean;
  className?: string;
}

export function InfoPiece(props: InfoPieceProps) {
  return (
    <Box flexDirection={props.list ? 'column' : 'row'} display={'flex'} alignItems='baseline' >
      <Typography
        variant="body2"
        mb={1}
        mr={2}
      >
        <b>{props.name}:</b>
      </Typography>
      <Box
        pb={1}
        pr={2}
        pl={props.list && !props.className ? 2 : 0}
        className={props.className}
        sx={{ width: '100%', overflowX: 'auto' }}
      >
        {props.content}
      </Box>
    </Box>
  );
}