import * as React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import {
  Sorting,
  sortableFields,
} from "../../helpers/sorting";
import { Filters } from "../../@types/apiTypes";
import { Offering } from "../../@types/courseTypes";
import { TableValues } from "../NewContentWrapper";
import { useFavorites } from "../../config/useFavorites";

import CourseTableRow from "../list-component/CourseTableRow";
import { LoadingRow } from "./LoadingRow";
import { THead } from "./TableHead";
import { TablePaginator } from "./TablePaginator";
import { messageRow } from "./MessageRow";



interface TableProps {
  rows: Offering[];
  totalResults: number;
  hasError?: string | null;
  isLoading: boolean;
  showFav?: boolean;
  tableInfo: TableValues;
  updateTable: React.Dispatch<React.SetStateAction<TableValues>>;
  filterOptions: Filters | undefined;
  disclaimer: string | undefined;
}

type FavTableInfo = {
  currentFavPage: number,
  rowsPerFavPage: number
}

/**
 * TableComponent - A React component that renders a sortable, paginated table with expandable rows
 *
 * @component
 * @param {Object} props - Component props
 * @param {Offering[]} props.rows - Array of course offering data to display in the table
 * @param {number} props.totalResults - Total number of results available (used for pagination)
 * @param {string|null} [props.hasError] - Error message to display if there's an issue loading data
 * @param {boolean} props.isLoading - Flag indicating if data is currently being loaded
 * @param {boolean} [props.showFav] - Flag to switch between showing all courses or only favorites
 * @param {TableValues} props.tableInfo - Object containing table state information: current page, number of rows per page, total number of rows and optional sorting
 * @param {React.Dispatch<React.SetStateAction<TableValues>>} props.updateTable - Function to update table state
 * @param {Filters|undefined} props.filterOptions - Available filter options for the table
 * @param {string|undefined} props.disclaimer - Optional disclaimer message to display
 *
 * @returns {JSX.Element} A Box component containing the table and pagination controls
 *
 * @description
 * TableComponent displays course offerings in a responsive table format with the following features:
 * - Sortable columns with ascending/descending order
 * - Expandable rows showing additional course details
 * - Pagination controls
 * - Favorite course functionality
 * - URL parameter integration for expanded rows
 * - Loading state indicators
 * - Responsive design for different screen sizes
 * - Error and empty state handling
 *
 * The component maintains several pieces of state:
 * - Sorting configuration (column and direction)
 * - Which row is currently expanded
 * - Pagination for both regular and favorites views
 * - URL parameters for bookmarking expanded courses
 *
 * It integrates with the favorites system through the useFavorites hook to allow
 * users to save courses for later viewing.
 */
export default function TableComponent(props: TableProps) {
  const { rows, hasError, isLoading, showFav, totalResults, filterOptions, disclaimer, tableInfo, updateTable } = props;
  const { rowsPerPage, currentPage } = tableInfo;
  const [searchParams, setSearchParams] = useSearchParams();

  const [sorting, setSorting] = React.useState<Sorting>({ order: "asc", orderBy: undefined });
  const [expandedRowId, setExpandedRowId] = React.useState<string | null>(null);

  const directNavigationNeeded = React.useRef<boolean>(false);
  const directNavigationTarget = React.useRef<{page: number, course: string} | null>(null);
  const hasScrolledToExpandedRow = React.useRef<boolean>(false);
  const tableContainerRef = React.useRef<HTMLTableSectionElement>(null);

  // Track the previous values of pagination and sorting to detect changes
  const [prevPage, setPrevPage] = React.useState<number>(currentPage);
  const [prevRowsPerPage, setPrevRowsPerPage] = React.useState<number>(rowsPerPage);
  const [prevSorting, setPrevSorting] = React.useState<Sorting>(sorting);

  const [favTableInfo, setFavTableInfo] = React.useState<FavTableInfo>({ currentFavPage: 1, rowsPerFavPage: 10 });

  const { favItems, addFavItem, removeFavItem } = useFavorites();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const message = disclaimer || (!props.isLoading && rows.length < 1 ? "No result" : hasError);

  // Get favorites for current page
  const getFavoritesForPage = () => {
    const start = (favTableInfo.currentFavPage - 1) * favTableInfo.rowsPerFavPage;
    const end = start + favTableInfo.rowsPerFavPage;
    return favItems.slice(start, end);
  };

  // Current list to display (all rows or favorites)
  const displayedRows = showFav ? getFavoritesForPage() : rows;

  // Handle favorites toggle
  const handleFavToggle = (item: Offering) => {
    const exists = favItems.some((course) => course.offeringId === item.offeringId);
    if (exists) {
      removeFavItem(item.offeringId);
    } else {
      addFavItem([...favItems, item]);
    }
  };

  const isFavorite = (item: Offering): boolean => {
    const findItem = favItems.some((course) => course.offeringId === item.offeringId);
    return Boolean(findItem);
  };

  // Handle sorting by column
  const handleRequestSort = (property: keyof typeof sortableFields) => {
    const isAsc = sorting.orderBy === property && sorting.order === "asc";
    const newSorting = {
      order: isAsc ? "desc" : "asc",
      orderBy: property
    };
    setSorting(newSorting as Sorting);
    updateTable({ ...tableInfo, sort: newSorting as Sorting, currentPage: 1 });
  };

  // Function to scroll to expanded row
  const scrollToExpandedRow = (courseId: string) => {
    if (!courseId) return;
    setTimeout(() => {
      const rowElement = document.getElementById(`course-row-${courseId}`);
      if (rowElement) {
        rowElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        hasScrolledToExpandedRow.current = true;
      }
    }, 300);
  };

  // Multi-approach scroll to top function with fallbacks
  const scrollToTop = () => {
    // Wait for DOM updates to complete
    setTimeout(() => {
      if (tableContainerRef.current) {
        try {
          tableContainerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
          return;
        } catch (e) {
          // Fall back to window scroll
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    }, 150);
  };

  // Handle row expansion with URL parameter update
  const handleRowExpansion = (courseId: string, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedRowId(courseId);
      const newParams = new URLSearchParams(searchParams);
      newParams.set("expandedCourse", courseId);
      setSearchParams(newParams);
      scrollToExpandedRow(courseId);
    } else {
      setExpandedRowId(null);
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("expandedCourse");
      setSearchParams(newParams);
    }
  };

  // Initial URL processing - runs only once on component mount
  React.useEffect(() => {
    const expandedCourseFromUrl = searchParams.get("expandedCourse");
    const pageFromUrl = searchParams.get("page");

    // If URL contains both expandedCourse and page parameters
    if (expandedCourseFromUrl && pageFromUrl) {
      const pageNumber = parseInt(pageFromUrl, 10);

      // Store for direct navigation if needed
      if (!isNaN(pageNumber) && pageNumber > 0) {
        directNavigationNeeded.current = true;
        directNavigationTarget.current = {
          page: pageNumber,
          course: expandedCourseFromUrl
        };
      }
    }
  }, []);

// Direct navigation handler - runs after each data fetch
React.useEffect(() => {
  // Skip if not needed or loading
  if (!directNavigationNeeded.current || isLoading) {
    return;
  }

  // Check if we need to navigate to a specific page for an expanded course
  if (directNavigationTarget.current) {
    const { page, course } = directNavigationTarget.current;

    // Check if we're on the right page
    if (page !== currentPage) {
      /* console.log(`Current page ${currentPage} doesn't match target page ${page}`); */

      // If we're showing course details and we need to change pages,
      // we need to force an immediate navigation
      if (expandedRowId) {
        setExpandedRowId(null);
      }

      // Update the table config to navigate to the right page
      // This will trigger a new data fetch through the parent component
      /* console.log(`Updating table to navigate to page ${page}`); */
      updateTable({
        ...tableInfo,
        currentPage: page
      });
    } else {
      // We're on the right page, check if the course is in the current data
      const courseExists = rows.some((row) => row.offeringId === course);

      if (courseExists) {
        /* console.log(`Found course ${course} on page ${page}, expanding it`); */
        setExpandedRowId(course);
        hasScrolledToExpandedRow.current = false;

        // Mission accomplished - direct navigation no longer needed
        directNavigationNeeded.current = false;
        directNavigationTarget.current = null;
      } else if (rows.length > 0) {
        // We're on the right page but course not found
        /* console.log(`Course ${course} not found on page ${page}`); */
        directNavigationNeeded.current = false;
        directNavigationTarget.current = null;
      }
    }
  }
}, [rows, isLoading, currentPage, tableInfo, updateTable]);

// Process URL expanded course parameter after data load
React.useEffect(() => {
  // Skip if already handling direct navigation or still loading
  if (directNavigationNeeded.current || isLoading || rows.length === 0) {
    return;
  }

  const expandedCourseFromUrl = searchParams.get("expandedCourse");

  // If URL has a course and we don't have an expanded row yet
  if (expandedCourseFromUrl && !expandedRowId) {
    // Check if it exists in current data
    const courseExists = rows.some((row) => row.offeringId === expandedCourseFromUrl);

    if (courseExists) {
      setExpandedRowId(expandedCourseFromUrl);
      hasScrolledToExpandedRow.current = false;
    } else {
      // Remove it from URL since we can't find it
      const newParams = new URLSearchParams();
      setSearchParams(newParams);
    }
  }
}, [rows, isLoading, searchParams, expandedRowId]);

  // Close expanded row when page, rows per page, or sorting changes
  React.useEffect(() => {
    // Check if any of the values have changed
    const paginationChanged = prevPage !== currentPage || prevRowsPerPage !== rowsPerPage;
    const sortingChanged = prevSorting.order !== sorting.order || prevSorting.orderBy !== sorting.orderBy;

    // Only handle if we have an expanded row and something changed
    if ((paginationChanged || sortingChanged) && expandedRowId) {
      if (!directNavigationNeeded.current) {
        setExpandedRowId(null);
        // Update URL to remove expanded course
        const newParams = new URLSearchParams(searchParams);
        newParams.delete("expandedCourse");
        setSearchParams(newParams);
      }
    }

    // Update the previous values
    setPrevPage(currentPage);
    setPrevRowsPerPage(rowsPerPage);
    setPrevSorting(sorting);
  }, [currentPage, rowsPerPage, sorting, expandedRowId]);

  // Effect for auto-scrolling to expanded row from URL params
  React.useEffect(() => {
    // Only scroll if we have an expanded row ID, URL params have been processed,
    // data has been loaded, and we haven't scrolled to this row yet
    if (expandedRowId /* && initialPaginationProcessed.current &&
      courseExpansionProcessed.current */ && !isLoading && !hasScrolledToExpandedRow.current) {
      setTimeout(() => {
        scrollToExpandedRow(expandedRowId);
      }, 500);
    }
  }, [expandedRowId, isLoading]);

   // When changing page via the paginator, scroll to top and handle related changes
   const handlePageChange = (page: number) => {
    // If we have a course expanded, close it first
    if (expandedRowId) {
      setExpandedRowId(null);

      // Update URL to remove expanded course
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("expandedCourse");
      setSearchParams(newParams);
    }
    props.updateTable({ ...tableInfo, currentPage: page });

    // If not handling expanded course, scroll to top of table
    if (!expandedRowId) {
      scrollToTop();
    }
  };

  // Handle changing rows per page
  const handleRowsPerPageChange = (length: number) => {
    // Close expanded row if any
    if (expandedRowId) {
      setExpandedRowId(null);

      // Update URL
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("expandedCourse");
      setSearchParams(newParams);
    }
    props.updateTable({ ...tableInfo, currentPage: 1, rowsPerPage: length });

    // If not handling expanded course, scroll to top of table
    if (!expandedRowId) {
      scrollToTop();
    }
  };


  return (
    <Box id="table-top-anchor" sx={{ width: "100%" }}>

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium" sx={{ borderCollapse: "separate" }}>
          <THead
            order={sorting.order}
            orderBy={sorting.orderBy}
            onRequestSort={handleRequestSort}
            isSmall={isSmallScreen}
            fav={showFav}
            tableTopRef={tableContainerRef}
          />
          <TableBody>
            {
              isLoading
                ?
                <LoadingRow />
                :
                rows.length > 0 ? (
                  displayedRows.map((course) => (
                    <CourseTableRow
                      courseItem={course}
                      expanded={course.offeringId === expandedRowId}
                      isSmall={isSmallScreen}
                      setExpanded={(expand) => {
                        handleRowExpansion(course.offeringId, expand);
                      }}
                      key={course.offeringId}
                      id={`course-row-${course.offeringId}`}
                      isFavored={isFavorite(course)}
                      handleFavorite={handleFavToggle}
                      filterOptions={filterOptions}
                    />
                  ))
                ) : (message && message !== "" && messageRow({ text: message }))
            }
          </TableBody>
        </Table>
      </TableContainer>
      {!props.showFav ?
        <TablePaginator
          totalResults={totalResults}
          currentPage={currentPage}
          noOfRows={rowsPerPage}
          setNewPage={handlePageChange}
          setPageLength={handleRowsPerPageChange}
        />
        :
        <TablePaginator
          totalResults={favItems.length}
          currentPage={favTableInfo.currentFavPage}
          noOfRows={favTableInfo.rowsPerFavPage}
          setNewPage={(page) => setFavTableInfo({ ...favTableInfo, currentFavPage: page })}
          setPageLength={(length) => { setFavTableInfo({ ...favTableInfo, currentFavPage: 1, rowsPerFavPage: length }); }}
        />
      }

    </Box>
  );
};
