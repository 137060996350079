import * as React from "react";
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


import { FilterComponent } from "./searchContainer/FilterComponent";
import TableComponent from "./newTable/Table";

import Api from "../config/api";

import { FilterFormProps, Filters, Payload } from "../@types/apiTypes";
import { LanguageProps, LevelProps, ModeOfDeliveryProps, UniversitiesProps } from "../@types/filterTypes";
import { Offering } from "../@types/courseTypes";
import { Sorting } from "../helpers/sorting";
import { TableTitle } from "./newTable/TableTitle";


export const initialValues = {
  university: [],
  q: "",
  modeOfDelivery: [],
  language: [],
  from: "",
  to: "",
  level: [],
  ectsMin: 0,
  ectsMax: 30
} as FilterFormProps;

export type TableValues = {
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  sort?: Sorting;
}
const initialTable = {
  currentPage: 1,
  rowsPerPage: 10,
  totalRows: 0
};

interface ContentWrapperProps {
  fav?: boolean;
}

/**
 * MainContentWrapper is a wrapper component providing a user interface for
 * displaying and interacting with a table of courses and filtering options.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} [props.fav=false] - Indicates whether to display favorite courses and hide filter component
 * @returns {JSX.Element} The rendered component
 */
const MainContentWrapper: React.FC<ContentWrapperProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterState, setFilterState] = React.useState<FilterFormProps>(initialValues);
  const [tableConfig, setTableConfig] = React.useState<TableValues>(initialTable);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [responseItems, setResponseItems] = React.useState<Offering[]>([]);

  const [showFavorites, setShowFavorites] = React.useState<boolean>(props.fav ?? false);
  const [filterOptions, setFilterOptions] = React.useState<Filters>();
  const [disclaimerMessage, setDisclaimerMessage] = React.useState<string | undefined>(undefined);

  /**
   * Effect to initialize filter state from URL parameters.
   */
  React.useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    const updatedFilters: FilterFormProps = {
      university: params.university ? params.university.split(",").map((value) => value as UniversitiesProps) : [],
      q: params.q || "",
      modeOfDelivery: params.modeOfDelivery ? params.modeOfDelivery.split(",").map((value) => value as ModeOfDeliveryProps) : [],
      language: params.language ? params.language.split(",").map((value) => value as LanguageProps) : [],
      from: params.from || "",
      to: params.to || "",
      level: params.level ? params.level.split(",").map((value) => value as LevelProps) : [],
      ectsMin: params.ectsMin ? Number(params.ectsMin) : initialValues.ectsMin,
      ectsMax: params.ectsMax ? Number(params.ectsMax) : initialValues.ectsMax,
    };

    const updatedTable: TableValues = {
      currentPage: params.page ? Number(params.page) : 1,
      rowsPerPage: params.rowsPerPage ? Number(params.rowsPerPage) : 10,
      totalRows: 0,
    };

    setFilterState(updatedFilters);
    setTableConfig(updatedTable);
  }, []);

  /**
   * Effect to sync filter state with URL parameters.
   */
  React.useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    ["university", "q", "modeOfDelivery", "language", "from", "to", "level",
      "ectsMin", "ectsMax", "page", "rowsPerPage"].forEach((param) => {
       newParams.delete(param);
     });

    if (filterState.university.length) newParams.set("university", filterState.university.join(","));
    if (filterState.q) newParams.set("q", filterState.q);
    if (filterState.modeOfDelivery.length) newParams.set("modeOfDelivery", filterState.modeOfDelivery.join(","));
    if (filterState.language.length) newParams.set("language", filterState.language.join(","));
    if (filterState.from) newParams.set("from", filterState.from);
    if (filterState.to) newParams.set("to", filterState.to);
    if (filterState.level.length) newParams.set("level", filterState.level.join(","));
    if (filterState.ectsMin !== initialValues.ectsMin) newParams.set("ectsMin", String(filterState.ectsMin));
    if (filterState.ectsMax !== initialValues.ectsMax) newParams.set("ectsMax", String(filterState.ectsMax));
    newParams.set("page", String(tableConfig.currentPage));
    newParams.set("rowsPerPage", String(tableConfig.rowsPerPage));

    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
  }, [filterState, tableConfig.currentPage, tableConfig.rowsPerPage]);

  /**
   * Effect to reset the current page when filters change.
   */
  React.useEffect(() => {
    setTableConfig((prevTableConfigState) => {
      return { ...prevTableConfigState, currentPage: 1 };
    });
  }, [filterState]);

  /**
   * Effect to fetch filter options from the API.
   */
  React.useEffect(() => {
    const controller = new AbortController();

    Api.importFilters()
      .then((filters) => {
        if (filters) {
          setFilterOptions(filters);
          setFilterState((prev) => ({ ...prev, ectsMax: filters.maxECT }));
        }
      })
      .catch((err: Error) => {
        if (!controller.signal.aborted) {
          setErrorMessage(err.message);
        }
      });
    return () => controller.abort();
  }, []);

  /**
   * Effect to fetch filtered course data from the API.
   */
  React.useEffect(() => {
    if (!filterOptions) return; // Wait until filter options are loaded
    /* set loading true to render loader and give
        ux feedback background loading is happening */
    setLoading(true);

    let payload: Payload = {
      ...filterState,
      pageSize: tableConfig.rowsPerPage,
      pageNumber: tableConfig.currentPage
    };

    if (tableConfig.sort) {
      payload['sort'] = [`${tableConfig.sort.order === 'asc' ? '' : '-'}${tableConfig.sort.orderBy}`];
    };

    Api.fetchFilter(payload)
      .then((data) => {
        if (data) {
          setDisclaimerMessage(undefined);
          setTableConfig((prevTableConfigState) => {
            return { ...prevTableConfigState, totalRows: data.totalResults };
          });
          setResponseItems(data.items);
          if (data.totalResults === 0
            && filterState.university.length === 1
            && filterState.q === ""
            && filterState.modeOfDelivery.length === 0
            && filterState.language.length === 0
            && filterState.from === ""
            && filterState.to === ""
            && filterState.level.length === 0
            && filterState.ectsMin === initialValues.ectsMin
            && filterState.ectsMax === initialValues.ectsMax
          ) {
            setDisclaimerMessage("No results found, this university doesn't seem to have any data in Enlight course catalogue yet");
          }
        }
      })
      .catch((err: Error) => {
        setErrorMessage(err.message);
      })
      .finally(() => setLoading(false));
  }, [filterState, tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.sort]);


  return (
    <Stack spacing={2} maxWidth="100%" mb={4}>
      <TableTitle
        showFavorite={showFavorites}
        setShowFavorites={setShowFavorites}
      />

      {!showFavorites &&
        <FilterComponent
          isLoading={loading}
          filterState={filterState}
          updateValues={setFilterState}
          filterOptions={filterOptions}
        />
      }

      <TableComponent
        rows={responseItems}
        isLoading={loading}
        totalResults={tableConfig.totalRows}
        hasError={errorMessage}
        tableInfo={tableConfig}
        updateTable={setTableConfig}
        showFav={showFavorites}
        filterOptions={filterOptions}
        disclaimer={!showFavorites ? disclaimerMessage : undefined}
      />

      <Button
        component={RouterLink}
        to="/application-process"
        variant="contained"
        color="enlightTeal"
        sx={{ maxWidth: 'sm', margin: 'auto', alignSelf: 'center', p: 2 }}
      >
        <Typography color="white" fontWeight={700}>
          See about application process
        </Typography>
      </Button>

    </Stack>
  );
};

export default MainContentWrapper;
