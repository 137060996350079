import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { Grid2 } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

import { getLabel, handleDate } from "../../helpers";
import { Filters } from "../../@types/apiTypes";
import { InfoPiece } from "./InfoPiece";
import { Offering } from "../../@types/courseTypes";
import OrganizationModal from "./OrganizationModal";


type CourseTableRowProps = {
  courseItem: Offering;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  isFavored: boolean;
  handleFavorite: (item: Offering) => void;
  isSmall: boolean;
  filterOptions: Filters | undefined;
  id: string;
};

/**
 * CourseTableRow component is responsible for rendering a table row which contains basic information about the course
 * as well as expandable section for extra information.
 * Uses potentially unsafe HTML escaping for compatibility
 */
const CourseTableRow: React.FC<CourseTableRowProps> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };



  const start = handleDate(props.courseItem.startDate);
  const end = handleDate(props.courseItem.endDate);

  const url = typeof props.courseItem.course !== 'string' &&
    props.courseItem.course.link?.startsWith("http")
    ? props.courseItem.course.link
    : "https://" + props.courseItem.course.link;

  const iconStyle = { color: "enlightTeal.main" };

  const tableCellStyles = {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
    padding: "1.5rem 0.5rem",
    border: "none"
  };

  const convertedLanguage = getLabel(props.filterOptions?.languages || [], props.courseItem.teachingLanguage.toLowerCase())
    ?? props.courseItem.teachingLanguage;

  const modeOfDelivery = typeof props.courseItem.course !== 'string' &&
    Array.isArray(props.courseItem.course?.modeOfDelivery) && props.courseItem.course.modeOfDelivery.length
    ? props.courseItem.course.modeOfDelivery
    : Array.isArray(props.courseItem.modeOfDelivery) && props.courseItem.modeOfDelivery.length
      ? props.courseItem.modeOfDelivery
      : [];


  const convertedModeOfDelivery = modeOfDelivery.map(
      (mode) => mode !== null && getLabel(props.filterOptions?.formats || [], mode.toLowerCase())
    ).join(', ');


  return (
    /**
     * Response should be expanded with course and organization information, not uuid
     */
    (typeof props.courseItem.course !== 'string' &&
      typeof props.courseItem.organization !== 'string') ? (
      <>
        <TableRow
          hover
          key={props.courseItem.offeringId}
          sx={{ boxShadow: props.expanded === true ? "0px 4px 16px rgba(23, 72, 106, 0.12)" : "none" }}
          id={props.id}
        >

          {props.isSmall ?
            <TableCell sx={{ paddingX: "1.5rem", fontSize: "1rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                <Typography fontWeight={700} fontSize={"1rem"} color="enlightTeal.main" >
                  {props.courseItem.name[0].value}
                </Typography>
                <Tooltip title={"Favouring a course does not register you to it, you still need to follow the application process"}>
                  <IconButton onClick={() => props.handleFavorite(props.courseItem)}>
                    {
                      props.isFavored
                        ? <StarIcon sx={{ color: "warning.main" }} />
                        : <StarBorderIcon sx={{ color: "enlightTeal.main" }} />
                    }
                  </IconButton>
                </Tooltip>
              </Box>
              <Grid2 container rowSpacing={1} columnSpacing={2} >
                <Grid2 size={{ xs:4 }}>ECTs:</Grid2>
                <Grid2 size={{ xs:8 }}>{props.courseItem.course.studyLoad?.value}</Grid2>
                <Grid2 size={{ xs:4 }}>University:</Grid2>
                <Grid2 size={{ xs:8 }}>
                  {props.courseItem.organization?.name[0].value}
                  {" "}
                  <IconButton
                    onClick={() => setOpenModal(true)}
                    color="primary"
                    size="small"
                    sx={{ transform: "translateY(-2px)", color: "enlightTeal.main", height: "fit-content" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Grid2>
                <Grid2 size={{ xs:4}}>Start date / end date:</Grid2>
                <Grid2 size={{ xs:8 }}>{`${start} / ${end}`}</Grid2>
              </Grid2>
              <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem", mt: 3, cursor: "pointer" }} onClick={() => {
                props.setExpanded(!props.expanded);
              }}>
                <Typography variant="body2" color="enlightTeal.main">{props.expanded ? "Close" : "Read more"}</Typography>
                {
                  props.expanded
                    ? <KeyboardArrowUpIcon sx={iconStyle} />
                    : <KeyboardArrowDownIcon sx={iconStyle} />
                }
              </Box>
            </TableCell>
            :
            <>
              <TableCell
                sx={{ ...tableCellStyles, pl: { xs: "0.25rem", md: "1.5rem" } }}
                onClick={() => {
                  props.setExpanded(!props.expanded);
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  sx={{ mr: 1.5 }}
                >
                  {
                    props.expanded
                      ? <KeyboardArrowUpIcon sx={iconStyle} />
                      : <KeyboardArrowDownIcon sx={iconStyle} />
                  }
                </IconButton>
              </TableCell>

              <TableCell
                onClick={() => {
                  props.setExpanded(!props.expanded);
                }}
                align="left"
                sx={{ ...tableCellStyles, color: "enlightTeal.main", fontWeight: 700 }}
              >
                {props.courseItem.name[0].value}
              </TableCell>

              <TableCell
                onClick={() => {
                  props.setExpanded(!props.expanded);
                }}
                align="left"
                sx={tableCellStyles}
              >
                {props.courseItem.course.studyLoad?.value}
              </TableCell>

              <TableCell
                align="left"
                sx={{ ...tableCellStyles, display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography
                  onClick={() => {
                    props.setExpanded(!props.expanded);
                  }}
                  fontSize="1rem"
                >
                  {props.courseItem.organization?.name[0].value}
                </Typography>
                <Tooltip title={"Information about the university"}>
                  <IconButton
                    onClick={() => setOpenModal(true)}
                    color="primary"
                    size="small"
                    sx={{ transform: "translateY(-2px)", color: "enlightTeal.main", height: "fit-content" }}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>

              <TableCell
                onClick={() => {
                  props.setExpanded(!props.expanded);
                }}
                align="left"
                sx={{ ...tableCellStyles, pt: 1, pb: 1 }}
              >
                {`${start} / ${end}`}
              </TableCell>

              <TableCell align="center" sx={{ ...tableCellStyles, pr: { xs: "0.25rem", md: "1.5rem" } }}>
                <Tooltip title={"Favouring a course does not register you to it, you still need to follow the application process"}>
                  <IconButton onClick={() => props.handleFavorite(props.courseItem)}>
                    {
                      props.isFavored
                        ? <StarIcon sx={{ color: "warning.main" }} />
                        : <StarBorderIcon sx={{ color: "enlightTeal.main" }} />
                    }
                  </IconButton>
                </Tooltip>
              </TableCell>

              {/* <TableCell align="center" sx={{ ...tableCellStyles }}>
                {props.courseItem.course.ext?.featured || ""}
              </TableCell> */}
            </>
          }
        </TableRow>

        <TableRow sx={{ background: "rgba(242, 247, 250, 0.5)" }}>
          <TableCell sx={{ border: "1px solid rgba(29, 100, 136, 0.1)", padding: 0, maxWidth: "100vh" }} colSpan={6}>
            <Collapse in={props.expanded} timeout="auto" unmountOnExit sx={{ padding: "1.5rem" }}>
              <Grid2 container spacing={3}>
                <Grid2 container rowSpacing={1} columnSpacing={3}>
                  <Grid2 size={{ xs:12, md:6 }} >

                    {
                      props.courseItem.academicSession?.name && (
                        <InfoPiece name='Academic session' content={props.courseItem.academicSession.name[0].value} />
                      )
                    }

                    <InfoPiece name='Level' content={props.courseItem.course.level} />

                    {
                      (props.courseItem.course.modeOfDelivery && props.courseItem.course.modeOfDelivery.length) && (
                        <InfoPiece name='Mode of delivery' content={convertedModeOfDelivery} />
                      )
                    }

                    <InfoPiece name='Language' content={convertedLanguage} />


                  </Grid2>
                  <Grid2 size={{ xs:12, md:6 }}>


                    {
                      (props.courseItem.course.admissionRequirements || props.courseItem.course.admissionRequirements_HTML) && (
                        <InfoPiece
                          name='Requirements for admission'
                          content={
                            props.courseItem.course.admissionRequirements_HTML
                              ? (<div dangerouslySetInnerHTML={{ __html: props.courseItem.course.admissionRequirements_HTML[0].value }} />)
                              : (props.courseItem.course.admissionRequirements && props.courseItem.course.admissionRequirements[0].value)
                          }
                          className={props.courseItem.course.admissionRequirements_HTML && 'htmlClasses'}
                        />
                      )
                    }

                    {
                      props.courseItem.course.qualificationRequirements && (
                        <InfoPiece
                          name='Requirements for admission'
                          content={props.courseItem.course.qualificationRequirements[0].value}
                        />
                      )
                    }

                    {
                      (props.courseItem.course.assessment || props.courseItem.course.assessment_HTML) && (
                        <InfoPiece
                          name='Assessment'
                          content={
                            props.courseItem.course.assessment_HTML
                              ? (<div dangerouslySetInnerHTML={{ __html: props.courseItem.course.assessment_HTML[0].value }} />)
                              : props.courseItem.course.assessment && props.courseItem.course.assessment[0].value}
                          className={props.courseItem.course.assessment_HTML && 'htmlClasses'}
                        />
                      )
                    }

                    {
                      props.courseItem.resultValueType && (
                        <InfoPiece name='Result' content={props.courseItem.resultValueType} />
                      )
                    }

                    <InfoPiece
                      name='Provided by'
                      content={
                        <>
                          {props.courseItem.organization?.name[0].value}
                          {" "}
                          <Tooltip title={"Information about the university"}>
                            <IconButton
                              onClick={() => setOpenModal(true)}
                              color="primary"
                              size="small"
                              sx={{ transform: "translateY(-2px)", color: "enlightTeal.main" }}
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    />

                  </Grid2>
                </Grid2>


                <Grid2 size={{ xs:12, md:6 }}>
                  {
                    ((props.courseItem.course.description && props.courseItem.course.description.length) || props.courseItem.course.description_HTML) && (
                      <InfoPiece
                        name='Description'
                        list
                        content={
                          props.courseItem.course.description_HTML
                            ? (<div dangerouslySetInnerHTML={{ __html: props.courseItem.course.description_HTML[0].value }} />)
                            : props.courseItem.course.description.map((desc, i) => <li key={i}>{desc.value}</li>)
                        }
                        className={props.courseItem.course.description_HTML && 'htmlClasses'}
                      />
                    )
                  }
                </Grid2>

                <Grid2 size={{ xs:12, md:6 }}>
                  {
                    (props.courseItem.course.learningOutcomes && !props.courseItem.course.learningOutcomes.every(function (v) { return v === null; })
                      || props.courseItem.course.learningOutcomes_HTML && !props.courseItem.course.learningOutcomes_HTML.every(function (v) { return v === null; })) && (

                      <InfoPiece
                        name='Learning outcomes'
                        list
                        content={
                          props.courseItem.course.learningOutcomes_HTML
                            ? props.courseItem.course.learningOutcomes_HTML.map((outcome, index) =>
                              outcome !== null && (
                                <Typography
                                  variant="body2"
                                  sx={{ marginLeft: "1rem" }}
                                  key={index}
                                >
                                  <li dangerouslySetInnerHTML={{ __html: outcome.value }}></li>
                                </Typography>
                              ))
                            : props.courseItem.course.learningOutcomes?.map((outcome, i) =>
                              outcome !== null && (
                                <li key={i}>{outcome.value}</li>
                              ))}
                        className={props.courseItem.course.learningOutcomes_HTML && 'htmlClasses'}
                      />
                    )
                  }
                </Grid2>

                {
                  props.courseItem.course.link && (
                    <Grid2 size={{ xs:12, md:6, sm: 'auto'}}>
                      <Button
                        variant="contained"
                        href={url}
                        target="_blank"
                        rel="nofollow"
                        color="enlightTeal"
                        size="small"
                        sx={{ p: "0.5rem 1.25rem", color: "white", fontWeight: 700, width: "100%" }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Go to Course Page
                      </Button>
                    </Grid2>
                  )
                }
                {
                  typeof props.courseItem.course !== 'string' && props.courseItem.course.modeOfDelivery?.includes("online") && (
                    <Grid2 size={{ xs:12, md:6, sm: 'auto' }}>
                      <Button
                        component={Link}
                        variant="contained"
                        to={`/register/${props.courseItem.course.courseId}`}
                        color="enlightTeal"
                        size="small"
                        sx={{ p: "0.5rem 1.25rem", color: "white", fontWeight: 700, width: "100%" }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Register to Online Course
                      </Button>
                    </Grid2>
                  )
                }
              </Grid2>
            </Collapse>
          </TableCell>
        </TableRow>

        {props.courseItem.organization && (
          <OrganizationModal
            isOpen={openModal}
            onClose={handleClose}
            organization={props.courseItem.organization}
          />
        )}

      </>
    )
      :
      <></>
  );
};

export default CourseTableRow;
